export const COLUMNS = [
    {
        Header: "Course Name",
        accessor: "Course_Name"
    },
    {
        Header: "Past",
        accessor: "Past_Takers"
    },
    {
        Header: "Current",
        accessor: "Current_Takers"
    }
]